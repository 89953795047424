const optsDefaultValues = {
  assetNatures: 'IMAGE',
  min: 1,
  max: 1,

  ordered: false,
  showSort: true,
  showPreview: true,
  showFilters: false,
  showLayout: false,
  showCursors: true,

  showCropper: true,
  showFocus: false,
  expectedWidth: null,
  expectedHeight: null,

  variations: 'thumbnailscreen',
  transformations: null,
  autoWebp: false,
  playerId: null,
  playerWidth: 560,
  playerHeight: 315,
  playerAutoStart: false,
  playerShareLinks: false,
  enableImagePlayer: false,

  cursor: null,
  fullText: null,

  configPath: null,
};

function checkDomItemIsEmpty(domItem) {
  if (!domItem) {
    throw new Error('Missing domItem parameter');
  }
  if (domItem.childNodes.length !== 0) {
    throw new Error('DisplayPicker, domItem should be an empty dom item');
  }
}

function createIframeForPicker(domItem, iframeSrc) {
  const iframe = document.createElement('iframe');
  iframe.src = iframeSrc;

  iframe.frameBorder = 0;
  // iframe.scrolling = 'no';
  iframe.allowTransparency = true;
  iframe.style = 'width: 100%;height: 100%;';
  iframe.setAttribute('style', 'width: 100%;height: 100%;');
  iframe.setAttribute('mozallowfullscreen', 'true');
  iframe.setAttribute('webkitallowfullscreen', 'true');
  iframe.setAttribute('allowfullscreen', 'true');

  iframe.setAttribute('allow', 'autoplay; fullscreen');

  domItem.appendChild(iframe);
}

const WediaContentPickerHandlers = [];

function addHandler(pickerIdentifier, eventName, handler) {
  if (!WediaContentPickerHandlers[pickerIdentifier]) {
    WediaContentPickerHandlers[pickerIdentifier] = [];
  }
  if (!WediaContentPickerHandlers[pickerIdentifier].onAssetPicked) {
    WediaContentPickerHandlers[pickerIdentifier].onAssetPicked = [];
  }
  WediaContentPickerHandlers[pickerIdentifier].onAssetPicked.push(handler);
}

function onPostMessageReceived(event) {
  const receivedIdentifier = event.data.pickerIdentifier;

  const handlers = WediaContentPickerHandlers[receivedIdentifier] || {};

  // eslint-disable-next-line no-param-reassign
  delete event.data.pickerIdentifier;

  (handlers.onAssetPicked || []).forEach((handler) => {
    handler(event.data);
  });
}

let initListenPostMessageEventInited = false;
function initListenPostMessageEvent() {
  if (initListenPostMessageEventInited) {
    return;
  }

  window.addEventListener('message', (event) => {
    onPostMessageReceived(event);
  }, false);
  initListenPostMessageEventInited = true;
}

const WediaContentPicker = {
  handlers: [],
  attach: (options = {}) => {
    const {
      el: domItem, server: portalBaseUrl, onPick /* , otherInputOptions */,
    } = options;

    if (!portalBaseUrl) {
      throw new Error('WediaContentPicker, missing server parameter in options');
    }

    const pickerIdentifier = `r${Math.random().toString(36).substring(7)}`;

    checkDomItemIsEmpty(domItem);
    addHandler(pickerIdentifier, 'onAssetPicked', onPick);

    let queryStringOptions = `pickerIdentifier=${encodeURIComponent(pickerIdentifier)}`;
    Object.keys(optsDefaultValues).forEach((configKey) => {
      let configKeyGetParameterName = `cp_${configKey}`;
      if (configKeyGetParameterName === 'cp_configPath') {
        configKeyGetParameterName = 'configPath'; // no cp_ prefix
      }
      let configValue = optsDefaultValues[configKey];
      if (typeof options[configKey] !== 'undefined') {
        configValue = options[configKey];
      }
      if (configKey === 'assetNatures' && configValue) {
        configValue = configValue.toLowerCase();
        configValue = configValue.replace('document', 'application');
        configValue = configValue.replace('zip', 'archive');
        configValue = configValue.replace('3d', 'model');
      }
      if (typeof configValue !== 'undefined' && configValue !== null) {
        if (Array.isArray(configValue)) {
          configValue = JSON.stringify(configValue);
        }
        if (configValue === true || configValue === false) {
          configValue = configValue ? 'true' : 'false';
        }
        queryStringOptions += `&${encodeURIComponent(configKeyGetParameterName)}=${encodeURIComponent(configValue)}`;
      }
    });
    // gestion de paramètres supplémentaires personnalisés
    // on prend toutes les options qui commencent par x_
    Object.entries(options).filter(([key]) => key.startsWith('x_')).forEach(([key, value]) => {
      if (typeof value !== 'undefined' && value !== null) {
        const extraValue = typeof value === 'object'
          ? JSON.stringify(value)
          : value;
        queryStringOptions += `&${encodeURIComponent(key)}=${encodeURIComponent(extraValue)}`;
      }
    });
    const iframeSrc = `${portalBaseUrl.replace(/\/+$/, '')}/asset-picker/?${queryStringOptions}`;
    createIframeForPicker(domItem, iframeSrc);

    initListenPostMessageEvent();
  },
};

export default WediaContentPicker;
